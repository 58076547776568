import React from "react"
import { Form, Select } from "antd"

const CommentStep = ({ field, itemName, isHidden }) => {
  const { Option } = Select
  return (
    <Form.Item
      noStyle
      shouldUpdate={(prevValues, currentValues) =>
        prevValues.appraisalPaths !== currentValues.appraisalPaths ||
        prevValues.templateType !== currentValues.templateType
      }
    >
      {({ getFieldValue }) => {
        return getFieldValue("appraisalPaths") &&
          getFieldValue("templateType") !== 3 &&
          getFieldValue("appraisalPaths").length > 0 ? (
          <Form.Item
            {...field}
            name={[field.name, itemName]}
            hidden={isHidden}
            rules={[{ required: !isHidden, message: "Please select a step" }]}
          >
            <Select placeholder="Select a step">
              {getFieldValue("appraisalPaths").map(
                (item, index) =>
                  item !== undefined && (
                    <Option value={index}>
                      {item.stepName
                        ? index + 1 + ". " + item.stepName
                        : "Step " + (index + 1)}
                    </Option>
                  )
              )}
            </Select>
          </Form.Item>
        ) : null
      }}
    </Form.Item>
  )
}

export default CommentStep
