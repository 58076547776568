import React, { useState, useEffect } from "react"
import Seo from "@components/seo"
import MainLayout from "@components/main_layout"
import { PageHeader } from "antd"
import "@style/template.less"
import {
  AppraisalTemplateClient,
  ApprovalRoleClient,
  CommentDefinitionClient,
  CoreCompetencyDefinitionSetClient,
  GoalSettingDefinitionSetClient,
} from "@api"
import { Loading } from "lib"
import { ConfigurationForm } from "@components/configuartion/Template"

const TemplateConfiguration = ({ location }) => {
  const { state = {} } = location
  const [isLoaded, setIsLoaded] = useState(false)
  const [record, setRecord] = useState(null)
  const [options, setOptions] = useState({
    goalOptions: [],
    coreOptions: [],
    commentOptions: [],
    roleOptions: [],
    three60Template: [],
  })

  useEffect(() => {
    const getDataById = async id => {
      const client = new AppraisalTemplateClient()
      let result = await client.get2(id)

      if (result.type === 2) {
        const frontlineResult = await client.getFrontline(id)

        const coreTokens = frontlineResult.coreTokens
          .map(item => ({
            index: parseInt(item.tokenCode.replace("core")),
            id: item.coreCompetencyDefinitionId,
          }))
          .sort((a, b) => a.index - b.index)

        const scoreTokens = frontlineResult.scoreTokens
          .map(item => ({
            index: parseInt(item.tokenCode.replace("score")),
            id: item.scoreAdjustmentDefinitionId,
          }))
          .sort((a, b) => a.index - b.index)

        result = {
          ...result,
          file: {
            uid: 1,
            name: frontlineResult.attachmentName,
            url: `${process.env.REACT_APP_URL}/DownloadFile`,
          },
          coreTokens: coreTokens.map(item => item.id),
          scoreTokens: scoreTokens.map(item => item.id),
        }
      }
      setRecord(result)
    }

    const initPage = async () => {
      try {
        if (!!state.id) await getDataById(state.id)
        const templateClient = new AppraisalTemplateClient()
        const templateResult = await templateClient.getOptions(
          true,
          true,
          "name",
          true
        )

        const commentClient = new CommentDefinitionClient()
        const commentResult = await commentClient.get(true, "id", true, 0, 0)
        const roleClient = new ApprovalRoleClient()
        const roleResult = await roleClient.get(0, 0, "name", true)

        const coreClient = new CoreCompetencyDefinitionSetClient()
        const coreResult = await coreClient.getOptions(true)

        const goalClient = new GoalSettingDefinitionSetClient()
        const goalResult = await goalClient.getOptions(true)
        setOptions({
          goalOptions: goalResult,
          coreOptions: coreResult,
          commentOptions: commentResult.list,
          roleOptions: roleResult.content.approvalRoleGroup,
          three60Template: templateResult,
        })
        setIsLoaded(true)
      } catch (err) {
        console.log(err)
        window.history.back()
      }
    }
    initPage()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <MainLayout>
      <Seo title="Template Configuration" />
      <PageHeader
        onBack={() => window.history.back()}
        title="Appraisal Template Configuration"
        ghost={false}
      />
      {isLoaded ? (
        <ConfigurationForm
          record={record}
          isCreate={state.isCreate}
          goalOptions={options.goalOptions}
          coreOptions={options.coreOptions}
          roleOptions={options.roleOptions}
          commentOptions={options.commentOptions}
          three60Template={options.three60Template}
        />
      ) : (
        <Loading />
      )}
    </MainLayout>
  )
}

export default TemplateConfiguration
