import React from "react"
import { Form, Row, Col, Button, Select,InputNumber } from "antd"
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons"
import CommentStep from "./CommentStep"

const Comments = ({ commentOptions }) => {
  const { Option } = Select
  return (
    <Form.List name="comments">
      {(fields, { add, remove }) => {
        return (
          <div>
            {fields.map(field => (
              <Row gutter={24} align="center">
                <Col span={16}>
                  <Form.Item
                    {...field}
                    rules={[
                      { required: true, message: "Please select a Comment" },
                    ]}
                    name={[field.name, "commentDefinitionId"]}
                  >
                    <Select
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      placeholder="Select a Comment"
                    >
                      {commentOptions.map((option, index) => (
                        <Option key={index} value={option.id}>
                          {option.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <CommentStep field={field} itemName="step" />
                </Col>
                <Col span={2}>
                  <Form.Item  {...field }   name={[field.name, "order"]}><InputNumber min={0} max={fields.length} ></InputNumber></Form.Item>
                </Col>
                <Col span={1}>
                  <MinusCircleOutlined
                    onClick={() => {
                      remove(field.name)
                    }}
                  />
                </Col>
              </Row>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => {
                  add()
                }}
                block
              >
                <PlusOutlined /> Add Comment
              </Button>
            </Form.Item>
          </div>
        )
      }}
    </Form.List>
  )
}

export default Comments
