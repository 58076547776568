import React, { useState, useEffect } from "react"
import {
  Form,
  Row,
  Col,
  Card,
  Button,
  Input,
  InputNumber,
  Checkbox,
  Select,
} from "antd"
import { AppraisalTemplateClient, TemplateType } from "@api"
import { showSuccess, errorMessage } from "@action"
import { showSelectProps } from "@object"
import ApprovalRoleList from "./ApprovalRoleList"
import Comments from "./Comments"
import { navigate } from "gatsby"

const ConfigurationForm = ({
  record,
  isCreate,
  goalOptions,
  coreOptions,
  commentOptions,
  roleOptions,
  three60Template,
}) => {
  const { Option } = Select
  const [isLoading, setIsLoading] = useState(false)
  const [form] = Form.useForm()
  const colProps = { span: 8 }

  const addStep = arr => {
    if (arr) {
      return arr.map((item, index) => {
        return {
          ...item,
          appraisalRoleType:
            item.appraisalRoleType >= 100 ? 2 : item.appraisalRoleType,
          approvalRoleId:
            item.appraisalRoleType >= 100 ? item.appraisalRoleType - 100 : null,
          step: index,
        }
      })
    }
    return []
  }

  const onFinish = async values => {
    setIsLoading(true)
    const client = new AppraisalTemplateClient()
    try {
      if (values.type !== 3 && values.type !== 2) {
        const submitValues = {
          ...values,
          appraisalPaths: addStep(values.appraisalPaths),
          degree360TemplateId: values.degree360TemplateId ?? 0,
        }

        if (isCreate) {
          await client.create(submitValues)
        } else {
          await client.edit(record.id, {
            id: record.id,
            ...submitValues,
          })
        }
      } else if (values.type === 2) {
        if (isCreate) {
          await client.createFrontline({
            ...values,
            appraisalPaths: addStep(values.appraisalPaths),
          })
        } else {
          await client.updateFrontline(record.id, {
            id: record.id,
            ...values,
            appraisalPaths: addStep(values.appraisalPaths),
          })
        }
      } else {
        if (isCreate) {
          console.log("create 360 form")
          await client.create360(values)
        } else {
          console.log("edit 360 record")
          await client.edit2(record.id, { id: record.id, ...values })
        }
      }
      showSuccess(
        isCreate
          ? "Add Template" + values.name + " Success"
          : "Edit " + values.name + "Success"
      )
      navigate("/admin_panel/template")
    } catch (err) {
      errorMessage(
        isCreate ? "Add Template" + values.name : "Edit " + values.name,
        err
      )
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (!!record) {
      const sortedList = record.appraisalTemplateApprovalPath.sort(
        (a, b) => a.step - b.step
      )
      const appraisalPaths = sortedList.map(item => {
        item.stepName = item.name
        if (item.appraisalRoleType === 2) {
          item.appraisalRoleType = item.approvalRoleId + 100
        }
        return { ...item }
      })
      form.setFieldsValue({
        ...record,
        appraisalPaths: appraisalPaths,
        comments: record.appraisalTemplateComment
          ? record.appraisalTemplateComment.map(item => {
              return { commentDefinitionId: item.commentDefinitionId, step: item.step, order:item.order }
            })
          : [],
      })
    } else {
      form.setFieldsValue({
        isNeedGoalSetting: false,
        isShowAttendanceRecord: false,
        degree360Weighting: 0,
        coreCompetencyWeighting: 0,
        siteKPIWeighting: 0,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Form form={form} onFinish={onFinish} scrollToFirstError>
      <Row gutter={24}>
        <Col {...colProps}>
          <Form.Item
            name="code"
            label="Template Code"
            rules={[{ required: true, message: "Please input a code" }]}
          >
            <Input placeholder="Input a Template Code" />
          </Form.Item>
        </Col>
        <Col {...colProps}>
          <Form.Item
            name="name"
            label="Template Name"
            rules={[{ required: true, message: "Please input a name" }]}
          >
            <Input placeholder="Input a Template Name" />
          </Form.Item>
        </Col>
        <Col {...colProps}>
          <Form.Item
            name="type"
            label="Template Type"
            rules={[{ required: true, message: "Please select a type" }]}
          >
            <Select
              placeholder="Please select a Type"
              disabled={
                !isCreate &&
                (record?.type === TemplateType.Frontline ||
                  record?.type === TemplateType.Degree360)
              }
            >
              <Option value={TemplateType.Managerial}>Managerial</Option>
              <Option value={TemplateType.NonManagerial}>Non-Managerial</Option>
              <Option value={TemplateType.Frontline}>Frontline</Option>
              <Option value={TemplateType.Degree360}>Degree360</Option>
            </Select>
          </Form.Item>
        </Col>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.type !== currentValues.type
          }
        >
          {({ getFieldValue }) =>
            getFieldValue("type") !== 3 ? (
              <>
                <Col>
                  <Form.Item
                    name="coreCompetencyWeighting"
                    label="Core Competencies Weighting"
                    rules={[
                      { required: true, message: "Please input a weighting" },
                    ]}
                  >
                    <InputNumber
                      min={0}
                      max={100}
                      formatter={value => `${value}%`}
                      parser={value => value.replace("%", "")}
                      step={10}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    name="siteKPIWeighting"
                    label="Site KPI Weighting"
                    rules={[
                      { required: true, message: "Please input a weighting" },
                    ]}
                  >
                    <InputNumber
                      min={0}
                      max={100}
                      formatter={value => `${value}%`}
                      parser={value => value.replace("%", "")}
                      step={10}
                    />
                  </Form.Item>
                </Col>
                {getFieldValue("type") !== 2 ? (
                  <Col>
                    <Form.Item label="Degree 360 Weighting" required>
                      <Input.Group compact>
                        <Form.Item
                          name="degree360Weighting"
                          rules={[
                            {
                              required: true,
                              message: "Please input a weighting",
                            },
                          ]}
                          style={{ margin: "0px 5px" }}
                        >
                          <InputNumber
                            min={0}
                            max={100}
                            formatter={value => `${value}%`}
                            parser={value => value.replace("%", "")}
                            step={10}
                          />
                        </Form.Item>
                        <Form.Item
                          noStyle
                          shouldUpdate={(prevValues, currentValues) =>
                            prevValues.degree360Weighting !==
                            currentValues.degree360Weighting
                          }
                        >
                          {({ getFieldValue }) => {
                            return getFieldValue("degree360Weighting") > 0 ? (
                              <Form.Item
                                name="degree360TemplateId"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select a template",
                                  },
                                ]}
                              >
                                <Select placeholder="Select a 360 Template">
                                  {three60Template.length > 0 &&
                                    three60Template.map(item => (
                                      <Option value={item.id}>
                                        {item.name}
                                      </Option>
                                    ))}
                                </Select>
                              </Form.Item>
                            ) : null
                          }}
                        </Form.Item>
                      </Input.Group>
                    </Form.Item>
                  </Col>
                ) : null}
              </>
            ) : null
          }
        </Form.Item>

        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.type !== currentValues.type
          }
        >
          {({ getFieldValue }) =>
            getFieldValue("type") !== 3 && getFieldValue("type") !== 2 ? (
              <Col>
                <Form.Item
                  name="isNeedGoalSetting"
                  label="Have Goal Setting"
                  valuePropName="checked"
                >
                  <Checkbox />
                </Form.Item>
              </Col>
            ) : null
          }
        </Form.Item>

        <Col>
          <Form.Item
            name="isShowAttendanceRecord"
            label="Show Attendance Record"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
        </Col>

        <Col {...colProps}>
          <Form.Item
            name="coreCompetencyDefinitionSetId"
            label="Core Competency Definition Set"
            rules={[
              {
                required: true,
                message: "Please select a Set",
              },
            ]}
          >
            <Select {...showSelectProps} placeholder="Select a Set">
              {coreOptions.map(option => (
                <Option key={option.id} value={option.id}>
                  {option.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.isNeedGoalSetting !== currentValues.isNeedGoalSetting ||
            prevValues.type !== currentValues.type
          }
        >
          {({ getFieldValue }) => {
            return getFieldValue("isNeedGoalSetting") &&
              getFieldValue("type") !== 3 &&
              getFieldValue("type") !== 2 ? (
              <Col {...colProps}>
                <Form.Item
                  name="goalSettingDefinitionSetId"
                  label="Goal Setting Definition Set"
                  rules={[
                    {
                      required: true,
                      message: "Please select a Set",
                    },
                  ]}
                >
                  <Select {...showSelectProps} placeholder="Select a Set">
                    {goalOptions.map(option => (
                      <Option key={option.id} value={option.id}>
                        {option.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            ) : null
          }}
        </Form.Item>
      </Row>

      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.type !== currentValues.type
        }
      >
        {({ getFieldValue }) =>
          getFieldValue("type") !== 3 ? (
            <Card title="Appraisal Path">
              <ApprovalRoleList roleOptions={roleOptions} />
            </Card>
          ) : null
        }
      </Form.Item>

      {/* Approval Path */}
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.type !== currentValues.type
        }
      >
        {({ getFieldValue }) =>
          getFieldValue("type") !== 2 ? (
            <Card title="Comment" style={{ margin: "20px 0px" }}>
              <Comments commentOptions={commentOptions} />
            </Card>
          ) : null
        }
      </Form.Item>

      {/*Comment part  */}

      <Row gutter={[12, 12]} justify="center">
        <Col>
          <Form.Item>
            <Button
              type="danger"
              shape="round"
              htmlType="submit"
              loading={isLoading}
            >
              {isCreate ? "Add" : "Change"}
            </Button>
          </Form.Item>
        </Col>
        <Col>
          <Button
            shape="round"
            disabled={isLoading}
            onClick={() => {
              window.history.back()
            }}
          >
            Cancel
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export default ConfigurationForm
