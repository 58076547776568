import React from "react"
import {
  Form,
  Row,
  Col,
  Button,
  Input,
  Space,
  Checkbox,
  Select,
  InputNumber,
} from "antd"
import {
  PlusOutlined,
  MinusCircleOutlined,
  DragOutlined,
} from "@ant-design/icons"
import SortableItem from "./SortableItem"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"

const ApprovalRoleList = ({ roleOptions }) => {
  const { Option } = Select

  const FormCard = ({ field, index, remove }) => {
    return (
      <Row gutter={8}>
        <Col span={1}>
          <DragOutlined />
        </Col>
        <Col span={7}>
          <Form.Item
            label={index + 1}
            {...field}
            name={[field.name, "stepName"]}
            key={(field.key, "stepName")}
            rules={[{ required: true, message: "Please input a step name" }]}
          >
            <Input placeholder="Step Name" />
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item
            {...field}
            name={[field.name, "appraisalRoleType"]}
            key={(field.key, "role")}
            rules={[{ required: true, message: "Please select a role" }]}
          >
            <Select placeholder="Select a Approval Role">
              <Option value={1}>Appraisee</Option>
              <Option value={0}>Immediate Supervisor</Option>
              <Option value={4}>SiteAdmin</Option>
              {roleOptions.map(item => (
                <Option value={item.id + 100}>{item.name}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            {...field}
            name={[field.name, "action"]}
            key={(field.key, "action")}
            rules={[{ required: true, message: "Please select a action" }]}
          >
            <Select placeholder="Select a action">
              <Option value="Submit">Submit</Option>
              <Option value="Review">Review</Option>
              <Option value="Approve">Approve</Option>
              <Option value="Acknowledge">Acknowledge</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={1}>
          <MinusCircleOutlined
            onClick={() => {
              remove(field.name)
            }}
          />
        </Col>
        <Col span={24}>
          <Space className="checkbox-space">
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.appraisalPaths !== currentValues.appraisalPaths
              }
            >
              {({ getFieldValue }) => {
                let values = getFieldValue("appraisalPaths")
                let isHidden = true
                if (
                  typeof values[field.name] != "undefined" &&
                  values[field.name].hasOwnProperty("appraisalRoleType")
                ) {
                  isHidden = values[field.name].appraisalRoleType !== 0
                }
                return (
                  <Form.Item
                    {...field}
                    label="Immediate Supervisor Level"
                    name={[field.name, "immeidateSupervisorLevel"]}
                    key={(field.key, "immeidateSupervisorLevel")}
                    hidden={isHidden}
                  >
                    <InputNumber step={1} min={0} type="number" />
                  </Form.Item>
                )
              }}
            </Form.Item>

            <Form.Item
              {...field}
              name={[field.name, "isNeedUseCoreCompetencies"]}
              key={(field.key, "isNeedUseCoreCompetencies")}
              valuePropName="checked"
              initialValue={false}
              label="Core Competency"
            >
              <Checkbox />
            </Form.Item>
            <Form.Item
              {...field}
              name={[field.name, "isNeedReviewComment"]}
              key={(field.key, "isNeedReviewComment")}
              valuePropName="checked"
              initialValue={false}
              label="Remarks/Comment"
            >
              <Checkbox />
            </Form.Item>

            <Form.Item
              {...field}
              name={[field.name, "hasBatchApprove"]}
              key={(field.key, "hasBatchApprove")}
              valuePropName="checked"
              initialValue={false}
              label="Batch Approve"
            >
              <Checkbox />
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.isNeedGoalSetting !== currentValues.isNeedGoalSetting
              }
            >
              {({ getFieldValue }) => (
                <Form.Item
                  {...field}
                  name={[field.name, "isNeedGoalSetting"]}
                  key={(field.key, "isNeedGoalSetting")}
                  valuePropName="checked"
                  label="Goal Setting"
                  hidden={!getFieldValue("isNeedGoalSetting")}
                >
                  <Checkbox />
                </Form.Item>
              )}
            </Form.Item>
            <Form.Item
              {...field}
              name={[field.name, "isHaveReturn"]}
              key={(field.key, "isHaveReturn")}
              valuePropName="checked"
              initialValue={false}
              label="Return"
            >
              <Checkbox />
            </Form.Item>

            <Form.Item
              {...field}
              name={[field.name, "isReturnSkipped"]}
              key={(field.key, "isReturnSkipped")}
              valuePropName="checked"
              initialValue={true}
              label="Skip Return"
            >
              <Checkbox />
            </Form.Item>
            <Form.Item
              {...field}
              name={[field.name, "isHaveRecall"]}
              key={(field.key, "isHaveRecall")}
              valuePropName="checked"
              initialValue={false}
              label="Recall"
            >
              <Checkbox />
            </Form.Item>
          </Space>
        </Col>
      </Row>
    )
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <Form.List name="appraisalPaths">
        {(fields, { add, remove, move }) => (
          <div>
            {fields.map((field, index) => (
              <SortableItem key={("Role", index)} index={index} move={move}>
                <FormCard field={field} index={index} remove={remove} />
              </SortableItem>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                block
                onClick={() => {
                  add()
                }}
              >
                <PlusOutlined /> Add Role
              </Button>
            </Form.Item>
          </div>
        )}
      </Form.List>
    </DndProvider>
  )
}

export default ApprovalRoleList
